import { red, grey } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
import "./styles/index.scss";

const { breakpoints } = createTheme({});

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#4592ae",
    },
    secondary: {
      main: "#ff7a59",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    text: {
      // secondary: "#2e3f4f",
      // paragraph: "#516f90",
      secondary: "#26292c",
      paragraph: "#2e3f4f",
      footer: "#646464",
      teritary: grey[600],
    },
  },
  typography: {
    fontFamily: "'Source Sans Pro', sans-serif",
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "initial",
      },
      containedSecondary: {
        color: "#FFF",
        fontWeight: 600,
        "&:hover": {
          color: "#FFF !important",
        },
      },
      contained: {
        boxShadow: "unset",
        "&:hover": {
          boxShadow: "unset",
        },
      },
      containedSizeLarge: {
        minWidth: 300,
        fontSize: 24,
        padding: "12px 28px",
        [breakpoints.down("sm")]: {
          minWidth: 200,
          fontSize: 20,
          padding: "10px 16px",
        },
      },
      outlinedSizeLarge: {
        minWidth: 300,
        fontSize: 24,
        padding: "12px 28px",
        [breakpoints.down("sm")]: {
          minWidth: 200,
          fontSize: 20,
          padding: "10px 16px",
        },
      },
    },
    MuiLink: {
      underlineHover: {
        "&:hover": {
          textDecoration: "none",
          color: "#315cb1",
        },
      },
    },
    MuiDivider: {
      light: {
        backgroundColor: "#dee9f4",
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "0 2px 4px rgba(45,62,80,0.12)",
      },
      colorDefault: {
        backgroundColor: "#FFFFFF",
      },
    },
    // MuiOutlinedInput: {
    // 	notchedOutline: {
    // 		borderColor: "rgb(255 0 0 / 0%) !important",
    // 	},
    // 	root: {
    // 		"&:hover .MuiOutlinedInput-notchedOutline": {
    // 			borderColor: "rgb(0 0 0 / 6%)",
    // 		},
    // 	},
    // },
    // MuiFormLabel: {
    // 	root: {
    // 		"& .Mui-focused": {
    // 			color: "rgb(0 0 0 / 6%)",
    // 		},
    // 	},
    // },
    // MuiInputBase: {
    // 	input: {
    // 		// height: 49,
    // 		padding: "14px 142px 15px 18px",
    // 		borderRadius: 5,
    // 		backgroundColor: "#f5f8fa",
    // 	},
    // },
    // PrivateNotchedOutline: {
    // 	legendLabelled: {
    // 		color: "#acb7c4",
    // 		fontSize: 16,
    // 	},
    // },
    MuiInputLabel: {
      outlined: {
        fontSize: 14,
      },
    },
  },
  drawerWidth: 280,
});

export default theme;
