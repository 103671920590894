import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Logo from "../../../images/v2/PC-logo.svg";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Link from "../Link";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid from "@material-ui/core/Grid";
import salesManagementImg from "../../../images/v2/home/product-dropdown/sales_pipeline_menu.svg";
import integrationImg from "../../../images/v2/home/product-dropdown/integration_menu.svg";
import securityImg from "../../../images/v2/home/product-dropdown/security_menu.svg";
import whatsappMessage from "../../../images/v2/home/features-images/whatsAppCrmDropdown.png";
import analyticsAndInsites from "../../../images/v2/home/features-images/analytics-dropdown.svg";
import leadCapture from "../../../images/v2/home/features-images/lead-capture-dropdwon.svg";
import inboxImg from "../../../images/v2/home/product-dropdown/inbox_menu.svg";
import chat from "../../../images/v2/home/product-dropdown/chat.svg";
import liveCircle from "../../../images/v2/home/livedot.png";
import whatsappCrm from "../../../images/v2/home/whatsapp-crm.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Drawer from "./Drawer";
import HeaderTypography from "../../../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../../../components/v2/Typography/ParagraphTypography";
import { useContactUsDialog } from "../ContactUsDialog";
import useWidth from "../../../hooks/useWidth";
import quotationSpanImg from "../../../images/v2/xero/quotation-bg-img.svg";

const useStyles = makeStyles((theme) => ({
  annoucementBannerRoot: {
    background: " linear-gradient(96deg, #37690a 50%, #428b02 83%);",
  },
  annoucementBannerHeight: {
    padding: ".25rem",
  },
  bannerText: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
    },
  },
  joinUsButton: {
    fontSize: 20,
    fontWeight: 600,
    marginLeft: 36,
    padding: "0px 12px",
    borderRadius: "0px",
    // padding: "0px",
    lineHeight: "normal",
    border: "1px solid #315CB1",
    borderWidth: "0 0 1px 0",
  },
  annoucementBannerClose: {
    color: "#2E3F4F",
  },
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    fontSize: 18,
    color: "#000 !important",
    padding: "6px 12px",
  },
  startBtn: {
    fontWeight: 600,
    fontSize: 18,
    minWidth: 130,
    color: "#fff",
    "&:hover": {
      color: "#000000",
    },
    [theme.breakpoints.between(0, 505)]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
    },
  },
  btnMargin: {
    marginRight: theme.spacing(1),
    color: "#000000",
    border: "1px solid rgb(0 0 0 / 62%)",
  },
  productButton: {
    fontSize: 18,
    color: "#000",
  },
  productWrapper: {
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    ...theme.mixins.toolbar,
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  paper: {
    padding: theme.spacing(1),
  },
  phone: {
    color: "#7c98b6",
    margin: 6,
    fontSize: 19,
  },
  contact: {
    marginRight: 18,
    [theme.breakpoints.between(0, 505)]: {
      marginRight: 0,
    },
  },
  expandIcon: {
    color: "#516f90",
  },
  productFeatureListItem: {
    marginBottom: ".75rem",
    "&:hover": {
      boxShadow: "rgba(51,71,91,0.12) 0 0 10px -3px",
    },
  },
  featureItemContainer: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
  link: {
    color: "#ffe600",
    paddingLeft: ".5rem",
    textDecoration: "underline",
    "&:hover": {
      color: "#ffe600",
    },
  },
  quotationSpan: {
    display: "inline-block",
    fontWeight: "bold",
    background: `url(${quotationSpanImg}) no-repeat`,
    width: "142px",
    height: "54px",
    verticalAlign: "middle",
    lineHeight: "49px",
    fontSize: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "38px",
      backgroundSize: "100%",
      width: "118px",
      height: "44px",
    },
  },
  whatsappcrmImg: {
    "@media(max-width:768px)": {
      display: "none",
    },
  },
  liveCircleImage: {
    height: "10px",
    width: "10px",
    position: "relative",
    background: "#fdca47",
    borderRadius: "50%",
    " &:after": {
      content: '""',
      position: "absolute",
      width: "20px",
      height: "20px",
      background: "#fdca47",
      opacity: "0.3",
      top: "-5px",
      left: "-5px",
      borderRadius: "50%",
      animation: "$live 1.5s infinite ease ",
    },
  },
  "@keyframes live": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(.8)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  bannerContent: {
    "@media(max-width:768px)": {
      marginRight: "0!important",
    },
    "@media(max-width:600px)": {
      fontSize: "14px",
    },
    "@media(max-width:520px)": {
      fontSize: "13px",
    },
    "@media(max-width:460px)": {
      fontSize: "12px",
    },
    "@media(max-width:380px)": {
      fontSize: "10px",
    },
  },
}));

export const productFeatureList = [
  {
    title: "WhatsApp CRM",
    img: whatsappMessage,
    description: "Integrate with WhatsApp and close deals right from your CRM.",
    path: "/whatsapp-crm",
  },
  {
    title: "Lead Capture",
    img: leadCapture,
    description: "Capture visitors into leads with customisable web forms.",
    path: "/lead-capture-generation-webform",
  },
  {
    title: "Sales Management",
    img: salesManagementImg,
    description: "Organise your Leads and Opportunities with sales pipelines.",
    path: "/sales-pipeline-management-tool",
  },
  {
    title: "Integrations",
    img: integrationImg,
    description:
      "Communicate efficiently with email, calendar, & other integrations.",
    path: "/integration",
  },
  {
    title: "Analytics",
    img: analyticsAndInsites,
    description:
      "Track performance and sales visibility through visual dashboards.",
    path: "/sales-dashboard",
  },
  {
    title: "Security",
    img: securityImg,
    description: "Safeguard your customers’ data with multi-layered security.",
    path: "/crm-data-security-protection",
  },
  {
    title: "Channels",
    img: chat,
    description: "Converse with customers from a single platform.",
    path: "/multichannel-integration-for-sales-marketing",
  },
  {
    title: "Inbox",
    img: inboxImg,
    description: "Access your emails and contacts from your CRM inbox.",
    path: "/crm-email-inbox-integration",
  },
];

const Index = ({ pathname }) => {
  const width = useWidth();
  const classes = useStyles();

  const isSPGPage = pathname === "/psg-grant" || pathname === "/psg-grant/";

  const [anchorEl, setAnchorEl] = useState(null);
  // const [contactAnchorEl, setContactAnchorEl] = useState(null);
  const [setOpenTrial] = useContactUsDialog();
  const [showBanner, setShowBanner] = useState(true);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => setAnchorEl(null);

  // const handleClick = (event) => {
  // 	setContactAnchorEl(event.currentTarget);
  // };
  //
  // const handleClose = () => {
  // 	setContactAnchorEl(null);
  // };

  // useEffect(() => {
  // 	const annoucementBannerClosed = window.localStorage.getItem("annoucementBannerClosed");
  // 	setShowBanner(!(annoucementBannerClosed === "true"));
  // }, []);

  const handleCloseAnnoucementBanner = () => {
    setShowBanner(false);
    // window.localStorage.setItem("annoucementBannerClosed", "true");
  };

  const glowForm = (ev) => {
    ev.preventDefault();
    const form = document.getElementById("psg-form");
    form.scrollIntoView({ behavior: "smooth", block: "end" });
    form.classList.remove("glow");
    form.classList.add("glow");
    setTimeout(() => {
      form.classList.remove("glow");
    }, 3200);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <AppBar color="default" position="sticky">
        {/* {true && (
          <Box
            className={
              classes.annoucementBannerRoot +
              " " +
              classes.annoucementBannerHeight
            }
          >
            <Box
              alignItems="center"
              display="flex !important"
              justifyContent="space-between"
            >
              <Box
                alignItems="center"
                className={classes.bannerText}
                color="#fff"
                display="flex"
                fontSize={20}
                fontWeight={600}
                justifyContent="center"
                m={0}
                textAlign="center"
                width="100%"
                letterSpacing={" 0.26px"}
              >
                <Box
                  component="div"
                  marginRight={"12px"}
                  className={classes.liveCircleImage}
                />
                <Box
                  className={classes.bannerContent}
                  component="span"
                  marginRight={"44px"}
                >
                  Live webinar: Sell instantly with WhatsApp CRM -
                  <Link
                    className={classes.link}
                    to="https://us06web.zoom.us/webinar/register/9017091069879/WN_lqMuCcwyRvK1QfpQFunRXg"
                  >
                    Register Now
                  </Link>
                </Box>
                <Box
                  component="img"
                  alt="Whatsapp Crm"
                  src={whatsappCrm}
                  marginRight={"12px"}
                  height={"32px"}
                  className={classes.whatsappcrmImg}
                />
              </Box>
            </Box>
          </Box>
        )} */}
        <Container>
          <Toolbar disableGutters={true}>
            <Drawer products={productFeatureList} width={width} />
            <Link className={classes.grow} to="/">
              <Box display="flex">
                <Box
                  alt="Pepper Cloud - Singapore's Best Sales CRM Software"
                  component={"img"}
                  height={{ sm: 40, xs: 34 }[width] || 40}
                  my={1}
                  src={Logo}
                />
              </Box>
            </Link>
            <Hidden implementation="css" smDown>
              <Box alignItems="center" display="flex">
                <div
                  aria-controls="pc-features-list"
                  aria-describedby="pc-features-popper"
                  aria-haspopup="true"
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  className={classes.productWrapper}
                  id="pc-features"
                  onClick={handlePopoverOpen}
                >
                  <Button
                    className={classes.productButton}
                    endIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                    fontSize={20}
                  >
                    {"Features"}
                  </Button>
                </div>
                <Popper
                  anchorEl={anchorEl}
                  className={classes.popper}
                  disablePortal
                  // keepMounted
                  id="pc-features-popper"
                  // keepMounted
                  open={open}
                  placement={"bottom-start"}
                  transition
                >
                  {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handlePopoverClose}>
                      <Fade {...TransitionProps} timeout={350}>
                        <Box component={Paper} maxWidth={950} p={2}>
                          <Grid
                            className={classes.featureItemContainer}
                            component="ul"
                            container
                            id="pc-features-list"
                            role="menu"
                          >
                            {productFeatureList.map((each) => (
                              <Grid
                                component="li"
                                item
                                key={each.title}
                                md={3}
                                role="none"
                                sm={12}
                              >
                                <Link
                                  alt={each.description}
                                  onClick={handlePopoverClose}
                                  role="menuitem"
                                  to={each.path}
                                >
                                  <Box
                                    alignItems="flex-start"
                                    className={classes.productFeatureListItem}
                                    display="flex"
                                    p={2}
                                  >
                                    <Box
                                      alt={each.title}
                                      component="img"
                                      height="50px"
                                      mr={2}
                                      src={each.img}
                                      width="50px"
                                    />
                                    <Box>
                                      <HeaderTypography
                                        fontSize={16}
                                        fontWeight={600}
                                        mb={0}
                                        mt={0}
                                      >
                                        {each.title}
                                      </HeaderTypography>
                                      <ParagraphTypography
                                        color="#516f90"
                                        fontSize={13}
                                        mb={0}
                                        mt={1}
                                      >
                                        {each.description}
                                      </ParagraphTypography>
                                    </Box>
                                  </Box>
                                </Link>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Fade>
                    </ClickAwayListener>
                  )}
                </Popper>
                <Button
                  className={classes.menuButton}
                  fontSize={20}
                  href="https://blog.peppercloud.com/"
                >
                  Blog
                </Button>
                <Button
                  className={classes.menuButton}
                  component={Link}
                  fontSize={20}
                  to="/careers"
                >
                  Careers
                </Button>
                <Button
                  className={classes.menuButton}
                  component={Link}
                  fontSize={20}
                  to="/crm-platfrom-pricing"
                >
                  Pricing
                </Button>
                <Button
                  className={classes.menuButton}
                  component={Link}
                  fontSize={20}
                  to="/psg-grant"
                >
                  PSG Grant
                </Button>
              </Box>
            </Hidden>
            <Button
              className={classes.startBtn + " " + classes.btnMargin}
              color="secondary"
              href="https://app.peppercloud.com/login"
              rel="noreferrer"
              size="small"
              target="_self"
              variant="outlined"
            >
              Login
            </Button>
            <Button
              className={classes.startBtn}
              color="secondary"
              component={Link}
              size="small"
              to="/contact"
              variant="contained"
            >
              Get Demo
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
};

export default Index;
