module.exports = {
  siteMetadata: {
    title: "Pepper Cloud CRM",
    author: "Pepper Cloud",
    description:
      "Pepper Cloud offers an intelligent Customer Relationship Management (CRM) system to know your customers better and boost up your business.",
    siteUrl: "https://peppercloud.com",
    pages: [
      {
        name: "Home",
        link: "/",
      },
      {
        name: "Integration",
        link: "/integration",
      },
      {
        name: "Sales Pipeline",
        link: "/sales-pipeline-management-tool",
      },
      {
        name: "Security",
        link: "/crm-data-security-protection",
      },
      {
        name: "Web Forms",
        link: "/lead-capture-generation-webform",
      },
      {
        name: "Dashboard",
        link: "/sales-dashboard",
      },
      {
        name: "Inbox",
        link: "/crm-email-inbox-integration",
      },
      {
        name: "Partner",
        link: "/become-crm-partner",
      },
      {
        name: "Contact Us",
        link: "/contact",
      },
      {
        name: "Pricing",
        link: "/crm-platfrom-pricing",
      },
      {
        name: "Singapore Ad V1",
        link: "/crm-software-for-small-business-singapore",
      },
      {
        name: "Singapore Ad V2",
        link: "/singapore-sme-v2",
      },
      {
        name: "Singapore Ad V3",
        link: "/singapore-whatsapp-crm",
      },
      {
        name: "Dubai AD",
        link: "/whatsapp-crm-system-dubai-uae",
      },
      {
        name: "Crm-With-Whatsapp-Integration",
        link: "/crm-with-whatsapp-integration",
      },
      {
        name: "Aircall-crm-integration",
        link: "/aircall-crm-integration",
      },
      {
        name: "Aircall-Page",
        link: "/crm-with-whatsapp-and-aircall-integration",
      },
      {
        name: "Crm Support",
        link: "/crm-support",
      },
      {
        name: "Privacy",
        link: "/privacy",
      },
      {
        name: "Join Us",
        link: "/careers",
      },
      {
        name: "Case Studies",
        link: "/crm-case-studies",
      },

      {
        name: "Eng Choon Enterprises",
        link: "/crm-case-studies/eng-choon-enterprise",
      },
      {
        name: "The Supreme HR Advisory",
        link: "/crm-case-studies/the-supreme",
      },
      {
        name: "Dreamcation",
        link: "/crm-case-studies/dreamcation",
      },
      {
        name: "SKYPAC Packaging",
        link: "/crm-case-studies/skypac-packaging",
      },
      {
        name: "Tech Data",
        link: "/crm-case-studies/tech-data",
      },
      {
        name: "Cuscapi",
        link: "/crm-case-studies/cuscapi",
      },
      {
        name: "Guest Blog",
        link: "/write-for-us",
      },
      {
        name: "Mobile CRM",
        link: "/best-mobile-crm-system-app-ios-android",
      },
      {
        name: "Cto-as-a-Service",
        link: "/cto-as-a-service",
      },
    ],

    linkedinUrl: "https://www.linkedin.com/company/peppercloud/",

    image: "/meta/logo.png",
  },
  plugins: [
    "gatsby-plugin-top-layout",
    {
      resolve: "gatsby-plugin-material-ui",
      // If you want to use styled components you should change the injection order.
      options: {
        // stylesProvider: {
        //   injectFirst: true,
        // },
      },
    },
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        trackingIds: ["UA-173483086-1", "AW-615460128"],
        gtagConfig: {
          anonymize_ip: false,
        },
        pluginConfig: {
          head: false,
        },
      },
    },
    "gatsby-plugin-sass",
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `testimonials`,
        path: `${__dirname}/src/images/v2/testimonials-images`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `home-dynamic`,
        path: `${__dirname}/src/images/v2/home/dynamic`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `channel`,
        path: `${__dirname}/src/images/v2/channel`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `sales-dynamic`,
        path: `${__dirname}/src/images/v2/sales-pipeline/dynamic`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `whatsapp-crm`,
        path: `${__dirname}/src/images/v2/whatsappcrm`,
      },
    },
    `gatsby-plugin-image`,
    {
      resolve: `gatsby-plugin-sharp`,
      options: {
        defaults: {
          formats: ["auto", "webp"],
          // placeholder: `tracedSVG`,
          backgroundColor: `transparent`,
          quality: 100,
          breakpoints: [750, 1080, 1366, 1920],
        },
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        serialize: (data) => {
          const { site, allSitePage } = data;
          return allSitePage.edges.map(({ node }) => {
            if (
              [
                "/",
                "/integration",
                "/sales-pipeline-management-tool",
                "/crm-data-security-protection",
                "/lead-capture-generation-webform",
                "/sales-dashboard",
                "/crm-email-inbox-integration",
              ].find((each) => each === node.path)
            ) {
              return {
                url: `${site.siteMetadata.siteUrl}${node.path}`,
                changefreq: `monthly`,
                priority: 1.0,
              };
            }
            return {
              url: `${site.siteMetadata.siteUrl}${node.path}`,
              changefreq: `monthly`,
              priority: 0.8,
            };
          });
        },
      },
    },
    "gatsby-plugin-robots-txt",
    "gatsby-plugin-remove-trailing-slashes",
    {
      resolve: "gatsby-plugin-robots-txt",
      options: {
        host: "https://peppercloud.com",
        sitemap: "https://peppercloud.com/sitemap.xml",
        policy: [{ userAgent: "*", allow: "" }],
      },
    },
    {
      resolve: "gatsby-plugin-html-attributes",
      options: {
        lang: "en",
      },
    },
    // {
    // 	resolve: `gatsby-plugin-offline`,
    // 	options: {
    // 		workboxConfig: {
    // 			globPatterns: ["**/*.{css,woff,woff2}"],
    // 			runtimeCaching: [
    // 				{
    // 					// Add runtime caching of various other page resources
    // 					urlPattern: /^https?:.*\.(png|jpg|jpeg|webp|svg|gif|tiff|js|woff|woff2|json|css)$/,
    // 					handler: `CacheFirst`,
    // 				},
    // 				{
    // 					// Google Fonts CSS (doesn't end in .css so we need to specify it)
    // 					urlPattern: /^https?:\/\/fonts\.googleapis\.com\/css/,
    // 					handler: `CacheFirst`,
    // 				},
    // 			],
    // 			skipWaiting: true,
    // 			clientsClaim: true,
    // 		},
    // 	},
    // },
    {
      resolve: "gatsby-plugin-manifest",
      options: {
        name: "Pepper Cloud CRM",
        short_name: "PC_CRM",
        start_url: "/",
        background_color: "#FFF",
        theme_color: "#4592ae",
        display: "standalone",
        icons: [
          {
            src: "icons/icon-image-16x16.png",
            sizes: "16x16",
            type: "image/png",
            purpose: "any maskable",
          },
          {
            src: "icons/icon-image-32x32.png",
            sizes: "32x32",
            type: "image/png",
            purpose: "any maskable",
          },
          // {
          // 	src: "icons/icon-48x48.png",
          // 	sizes: "48x48",
          // 	type: "image/png",
          // 	purpose: "any maskable",
          // },
          {
            src: "icons/icon-image-72x72.png",
            sizes: "72x72",
            type: "image/png",
            purpose: "any maskable",
          },
          // {
          // 	src: "icons/icon-96x96.png",
          // 	sizes: "96x96",
          // 	type: "image/png",
          // 	purpose: "any maskable",
          // },
          {
            src: "icons/icon-image-144x144.png",
            sizes: "144x144",
            type: "image/png",
            purpose: "any maskable",
          },
          {
            src: "icons/icon-image-192x192.png",
            sizes: "192x192",
            type: "image/png",
            purpose: "any maskable",
          },
          // {
          // 	src: "icons/icon-256x256.png",
          // 	sizes: "256x256",
          // 	type: "image/png",
          // 	purpose: "any maskable",
          // },
          // {
          // 	src: "icons/icon-384x384.png",
          // 	sizes: "384x384",
          // 	type: "image/png",
          // 	purpose: "any maskable",
          // },
          {
            src: "icons/icon-image-512x512.png",
            sizes: "512x512",
            type: "image/png",
            purpose: "any maskable",
          },
        ],
      },
    },
    "gatsby-plugin-preact",
    // {
    //   resolve: 'gatsby-plugin-webpack-bundle-analyzer',
    //   options: {
    //     analyzerPort: 3000,
    //     production: true
    //   },
    // },
    // {
    //   resolve: `gatsby-plugin-react-helmet-canonical-urls`,
    //   options: {
    //     siteUrl: `https://www.peppercloud.com`,
    //   },
    // },

    // {
    //   resolve: `gatsby-plugin-feed`,
    //   options: {
    //     query: `
    //       {
    //         site {
    //           siteMetadata {
    //             title
    //             description
    //             siteUrl
    //             site_url: siteUrl
    //           }
    //         }
    //       }
    //     `,
    //     feeds: [
    //       {
    //         serialize: ({ query: { site, allMarkdownRemark } }) => {
    //           return allMarkdownRemark.edges.map(edge => {
    //             return Object.assign({}, edge.node.frontmatter, {
    //               description: edge.node.excerpt,
    //               date: edge.node.frontmatter.date,
    //               url: site.siteMetadata.siteUrl + edge.node.fields.slug,
    //               guid: site.siteMetadata.siteUrl + edge.node.fields.slug,
    //               custom_elements: [{ "content:encoded": edge.node.html }],
    //             })
    //           })
    //         },
    //         query: `
    //           {
    //             allMarkdownRemark(
    //               sort: { order: DESC, fields: [frontmatter___date] },
    //             ) {
    //               edges {
    //                 node {
    //                   excerpt
    //                   html
    //                   fields { slug }
    //                   frontmatter {
    //                     title
    //                     date
    //                   }
    //                 }
    //               }
    //             }
    //           }
    //         `,
    //         output: "/rss.xml",
    //         title: "Your Site's RSS Feed",
    //         // optional configuration to insert feed reference in pages:
    //         // if `string` is used, it will be used to create RegExp and then test if pathname of
    //         // current page satisfied this regular expression;
    //         // if not provided or `undefined`, all pages will have feed reference inserted
    //         match: "^/blog/",
    //       },
    //     ],
    //   },
    // },
    // {
    //   resolve: `gatsby-plugin-google-analytics`,
    //   options: {
    //     trackingId: "YOUR_GOOGLE_ANALYTICS_TRACKING_ID",
    //     // Defines where to place the tracking script - `true` in the head and `false` in the body
    //     head: false,
    //     // Setting this parameter is optional
    //     anonymize: true,
    //     // Setting this parameter is also optional
    //     respectDNT: true,
    //     // Avoids sending pageview hits from custom paths
    //     exclude: ["/preview/**", "/do-not-track/me/too/"],
    //     // Delays sending pageview hits on route update (in milliseconds)
    //     pageTransitionDelay: 0,
    //     // Enables Google Optimize using your container Id
    //     optimizeId: "YOUR_GOOGLE_OPTIMIZE_TRACKING_ID",
    //     // Enables Google Optimize Experiment ID
    //     experimentId: "YOUR_GOOGLE_EXPERIMENT_ID",
    //     // Set Variation ID. 0 for original 1,2,3....
    //     variationId: "YOUR_GOOGLE_OPTIMIZE_VARIATION_ID",
    //     // Any additional optional fields
    //     sampleRate: 5,
    //     siteSpeedSampleRate: 10,
    //     cookieDomain: "example.com",
    //   },
    // },
  ],
};
